<template>
  <div>
    <!-- 新增内容 -->
    <el-row style="border: 1px solid #eee;padding:10px 20px;" v-if="addModelShow">
      <el-col :span="3" width="border-right:1px solid #eee;display:flex;align-items: center;justify-content:center;">
        <div class="blockBtnWrap">
          <div class="imageBox" :style="isJoin ? 'background:rgb(189, 49, 36)':'background:rgb(129, 179, 55)'">
            <el-image
              style="width: 40px; height: 40px"
              :src="isJoin ? require('@/assets/images/block_join.svg'):require('@/assets/images/block_remove.svg')"
            ></el-image>
          </div>
          <el-select v-model="isJoin" placeholder="请选择" @change="changeBlockBtn">
                <el-option
                    v-for="item in blockSelectOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
          <div>{{nowTime}}</div>
        </div>
      </el-col>
      <el-col :span="21" >
          <div>
            <div style="text-align:right;margin-bottom:10px;margin-right:5px;">
                <el-button type="primary" size="mini" v-loading="dialogLoading" @click="addSubmitBtn">保存</el-button>
                <el-button size="mini" @click="addCancelBtn">取消</el-button>
            </div>
            <avue-form :option="addOptions" ref="addForm" v-model="addForm" @submit="addSubmit" class="remove-avue-form-menu">
                <template slot="prjCode">
                    <el-input placeholder="请选择" v-model="addForm.prjCode" @focus="clickOpenProjectSelection">
                    </el-input>
                </template>
                <template slot="companyName">
                    <el-input placeholder="请选择" v-model="addForm.companyName" @focus="clickOpenCompanySelection">
                    </el-input>
                </template>
            </avue-form>
            <uploader @getTableData="getTableData" :fileSize="20" accept=".jpg,.png,.pdf,.gif,.jpeg,.ppt,.pptx,.doc,.docx,.xls,.xlsx" :uploadLimit="50" :multiple="true" :fileOptions="fileOptions" :gridRowBtn="fileGridRowBtn" ref="uploader"></uploader>
          </div>
      </el-col>
    </el-row>
    <!-- 详情内容 -->
    <el-row style="border: 1px solid #eee;padding:10px 20px;" v-for="(item,index) in detailList" :key="index">
      <el-col :span="3" width="border-right:1px solid #eee;display:flex;align-items: center;justify-content:center;">
        <div class="blockBtnWrap">
          <div class="imageBox" :style="item.blState == 1 ? 'background:rgb(189, 49, 36)':'background:rgb(129, 179, 55)'">
            <el-image
              style="width: 40px; height: 40px"
              :src="item.blState == 1 ? require('@/assets/images/block_join.svg'):require('@/assets/images/block_remove.svg')"
            ></el-image>
          </div>
          <div style="line-height:38px">{{ item.blState== 1 ? '加入黑名单' : '解除黑名单' }}</div>
          <div>{{item.updateTime}}</div>
        </div>
      </el-col>
      <el-col :span="21" >
          <div>
            <div v-if="formType != 'view'" style="text-align:right;margin-bottom:10px;margin-right:5px;"><el-button type="primary" size="mini" @click="itemSaveBtn('itemRef'+index,item,index)">保存</el-button></div>
            <avue-form :option="item.blState == 1 ? joinOptions : removeOptions" :ref="'itemRef'+index" v-model="detailList[index]" class="remove-avue-form-menu">
            </avue-form>
            <uploader :ref="'uploaderItem'+index" @getTableData="(data) => getItemTableData(data,'itemRef'+index,item,index)" :tableFiles="changeTable(item.files,index)" :fileSize="20" accept=".jpg,.png,.pdf,.gif,.jpeg,.ppt,.pptx,.doc,.docx,.xls,.xlsx" :uploadLimit="50" :multiple="true" :fileOptions="fileOptions" :gridRowBtn="fileGridRowBtn" :disabled="formType == 'view'"></uploader>
          </div>
      </el-col>
    </el-row>
    <!-- 新增加入黑名单选择项目 -->
    <selectProjectDialogVue ref="selectProjectDialog" @changeProject="changeProject" :phone="phone"></selectProjectDialogVue>
    <!-- 新增加入黑名单选择企业 -->
    <blackCompanyDialogVue ref="blackCompanyDialog" :isPerson="true" @changeCompany="changeCompany"></blackCompanyDialogVue>
</div>
</template>
<script>
import selectProjectDialogVue from '@/views/business/safe/blacklistOrganization/components/selectProjectDialog.vue';
import blackCompanyDialogVue from '@/views/business/safe/blacklistOrganization/components/blackCompanyDialog.vue';
import uploader from "@/components/file-upload/index";
import {
    prjbasicinfoDetail
    
  } from "@/api/safe/itemList";
import {
    prjblacklistuserheadSubmit,
    prjblacklistuserlistSubmit,
    prjblacklistuserlist
} from "@/api/block/personnel";
export default {
  props:['userId','formType','phone'], // 去查相关方人员的项目时候用phone
  data() {
    return {
        addModelShow:false, // 默认不展示新增
        isJoin:true, // true 加入黑名单 false 移除黑名单
        nowTime:'',
        addForm:{},
        detailList:[],
        refs:{},
        detailListId:'',
        blockSelectOptions:[
            {
                label:'加入黑名单',
                value:true
            },
            {
                label:'移除黑名单',
                value:false
            }
        ],
        fileOptions:{
            linklabel:'name',
            column:[
                {
                    label: '附件名',
                    prop: "name",
                    align: "left",
                    minWidth:200,
                    overHidden: true,
                },
                {
                    label: '附件大小',
                    prop: "fileSize",
                    align: "center",
                    overHidden: true,
                },
                {
                    label: '附件格式',
                    prop: "extension",
                    align: "center",
                    overHidden: true,
                },
                {
                    label: '上传人',
                    prop: "createUserName",
                    align: "center",
                    overHidden: true,
                },
                {
                    label: '上传时间',
                    prop: "createTime",
                    align: "center",
                    overHidden: true,
                },
            ]
        },
        fileGridRowBtn:[
            {
                label: '下载',
                emit: "row-download",
            },
            {
                label: '删除',
                emit: "row-del",
            },
        ]
    };
  },
  components:{
    selectProjectDialogVue,
    blackCompanyDialogVue,
    uploader
  },
  computed:{
    addOptions() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 130,
          column: [
            {
                label: '项目编号',
                prop: "prjCode",
                span: 8,
                formslot: true,
                disabled: true,
                display:this.isJoin == true,
                placeholder: '请输入项目编号',
                rules: [{
                    required: true,
                    message: "请输入项目编号"
                }]
            },
            {
                label: '项目名称',
                prop: "prjName",
                span: 8,
                disabled: true,
                display:this.isJoin == true,
                placeholder: '请输入项目名称'
            },
            {
                label: '境内外',
                prop: "isDomestic",
                span: 8,
                type: 'select',
                disabled: true,
                display:this.isJoin == true,
                placeholder: '请选择境内外',
                dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_domestic`,
                props: {
                    label: 'dictValue',
                    value: 'dictKey',
                },
            },
            {
                label: '备案单位',
                prop: "contractName",
                span: 8,
                disabled: true,
                display:this.isJoin == true,
                placeholder: '请输入备案单位'
            },
            {
                label: '项目区域',
                prop: "areName",
                span: 8,
                disabled: true,
                display:this.isJoin == true,
                placeholder: '请输入项目区域'
            },
            {
                label: '立项日期',
                prop: "prjDate",
                span: 8,
                type: "date",
                format: "yyyy-MM-dd",
                valueFormat: "yyyy-MM-dd",
                disabled: true,
                display:this.isJoin == true,
                placeholder: '请输入立项日期'
            },
            {
                label: '企业名称',
                prop: "companyName",
                span: 8,
                formslot: true,
                disabled: true,
                display:this.isJoin == true,
                placeholder: '请输入企业名称',
                rules: [{
                    required: true,
                    message: "请输入企业名称"
                }]
            },
            {
                label: '统一社会信用代码',
                prop: "companyCode",
                span: 8,
                disabled: true,
                display:this.isJoin == true,
                placeholder: '请输入统一社会信用代码'
            },
            {
                label: '注册地',
                prop: "regLocation",
                span: 8,
                disabled: true,
                display:this.isJoin == true,
                placeholder: '请输入注册地'
            },
            {
                label: this.isJoin == true ? "说明" : "解除说明",
                type:'textarea',
                placeholder:'请填写',
                prop: "illustrate",
                span:24,
                minRows: 6,
                rules: [{
                  required: true,
                  message: "请输入说明",
                  trigger: "blur"
                }]
            }
          ],
        }
    },
    joinOptions() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 130,
          column: [
            {
                label: '项目编号',
                prop: "prjCode",
                span: 8,
                disabled: true,
                placeholder: '请输入项目编号',
                rules: [{
                    required: true,
                    message: "请输入项目编号",
                }]
            },
            {
                label: '项目名称',
                prop: "prjName",
                span: 8,
                disabled: true,
                placeholder: '请输入项目名称'
            },
            {
                label: '境内外',
                prop: "isDomestic",
                span: 8,
                type: 'select',
                dataType: 'string',
                disabled: true,
                placeholder: '请选择境内外',
                dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_domestic`,
                props: {
                    label: 'dictValue',
                    value: 'dictKey',
                },
            },
            {
                label: '备案单位',
                prop: "contractName",
                span: 8,
                disabled: true,
                placeholder: '请输入备案单位'
            },
            {
                label: '项目区域',
                prop: "areName",
                span: 8,
                disabled: true,
                placeholder: '请输入项目区域'
            },
            {
                label: '立项日期',
                prop: "prjDate",
                span: 8,
                type: "date",
                format: "yyyy-MM-dd",
                valueFormat: "yyyy-MM-dd",
                disabled: true,
                placeholder: '请输入立项日期'
            },
            {
                label: '企业名称',
                prop: "companyName",
                span: 8,
                disabled: true,
                placeholder: '请输入企业名称',
                rules: [{
                    required: true,
                    message: "请输入企业名称",
                }]
            },
            {
                label: '统一社会信用代码',
                prop: "companyCode",
                span: 8,
                disabled: true,
                placeholder: '请输入统一社会信用代码'
            },
            {
                label: '注册地',
                prop: "regLocation",
                span: 8,
                disabled: true,
                placeholder: '请输入注册地'
            },
            {
                label: "说明",
                type:'textarea',
                placeholder:'请填写',
                prop: "illustrate",
                span:24,
                minRows: 6,
                disabled:this.formType == 'view',
                rules: [{
                  required: true,
                  message: "请输入说明",
                  trigger: "blur"
                }]
            },
            {
                label: '拉黑人',
                prop: "createUserName",
                span: 8,
                disabled: true,
            },
            {
                label: '拉黑人所属组织',
                prop: "createDeptName",
                span: 8,
                disabled: true,
            },
            {
                label: '拉黑时间',
                prop: "createTime",
                span: 8,
                type: "date",
                format: "yyyy-MM-dd HH:mm:ss",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                disabled: true,
                placeholder: '请输入拉黑时间'
            },
          ],
        }
    },
    removeOptions(){
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 130,
          column: [
            {
                label: "解除说明",
                type:'textarea',
                placeholder:'请填写',
                prop: "illustrate",
                span:24,
                minRows: 6,
                disabled:this.formType == 'view',
                rules: [{
                  required: true,
                  message: "请输入解除说明",
                  trigger: "blur"
                }]
            },
            {
                label: '解除人',
                prop: "createUserName",
                span: 8,
                disabled: true,
            },
            {
                label: '解除人所属组织',
                prop: "createDeptName",
                span: 8,
                disabled: true,
            },
            {
                label: '解除时间',
                prop: "createTime",
                span: 8,
                type: "date",
                format: "yyyy-MM-dd HH:mm:ss",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                disabled: true,
                placeholder: '请输入解除时间'
            },
          ],
        }
    }
  },
  mounted(){
      this.getNowDate()
  },
  methods:{
    //   新增内容
    // 上传附件
    //   组合附件数据
    resetFileData(data){
        let arr = [ ]
        data.forEach((ele,ind)=>{
            arr.push({
                createTime:ele.createTime,
                createUser:ele.createUser,
                createUserName:ele.createUserName,
                extension:ele.extension,
                fileSize:ele.fileSize,
                id:ele.id,
                path:ele.path,
                schedule:ele.schedule,
                uid:ele.uid,
                name:ele.name,
                size:ele.size
            })
        })
        return JSON.stringify(arr)
    },
    //文件上传成功后的回调函数 新增
    getTableData(data){
        this.addForm.files = this.resetFileData(data)
    },
    // 详情中修改table中的文件
    changeTable(data,index){
        if(data){
            this.$nextTick(()=>{
                this.$refs['uploaderItem'+index][0].initTaleData(data)
            })
        }
    },
    // 修改详情的中的文件上传
    getItemTableData(data,refsName,item,index){
        item.files = this.resetFileData(data)
        this.itemSaveBtn(refsName,item,index)
    },
    //   获取当前时间
    getNowDate(){
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1;
        const day = now.getDate();
        this.nowTime = year + '-' + month + '-' + day
    },
    // 加入黑名单 移除黑名单切换 新增
    changeBlockBtn(e){
        this.isJoin = e
        this.$refs.uploader.initTaleData()
        this.$refs.addForm.resetFields()
    },
    // 加入黑名单保存
    addSubmit(){
        this.$refs.addForm.submit();
    },
    addSubmitBtn(data) {
        this.$refs.addForm.validate((valid, done) => {
            if (valid) {
                this.dialogLoading = true
                let queryData = {
                    blState:this.isJoin == true ? 1 : 0,
                    companyCode:this.addForm.companyCode,
                    illustrate:this.addForm.illustrate,
                    prjCode:this.addForm.prjCode,
                    // userId:this.userId,
                    phone: this.phone,
                    files:this.addForm.files
                }
              this.$loading();
                prjblacklistuserheadSubmit(queryData).then(res => {
                    const {
                        msg,
                        data
                    } = res.data;
                    if (res.data.code == 200) {
                        this.$refs.uploader.initTaleData()
                        this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
                    } else {
                        this.$message.success(msg);
                    }
                    this.detailListId = data.id
                    this.initDetail(this.detailListId)
                    this.$refs.addForm.resetFields()
                    done()
                    this.dialogLoading = false
                  this.$loading().close();
                }).catch(err => {
                    done()
                  this.$loading().close();
                }).finally(() => {
                    this.dialogLoading = false
                })
            }
        })
    },
    // 取消新增
    addCancelBtn(){
        this.addModelShow = false
        this.$refs.uploader.initTaleData()
        this.$refs.addForm.resetFields()
    },
    // 选择项目
    clickOpenProjectSelection(){
        this.$refs.selectProjectDialog.init()
    },
    // 选择项目弹窗返回的id
    changeProject(row){
        this.getPrjbasicinfoDetail(row.id)
    },
    // 根据ID查表单详情
    getPrjbasicinfoDetail(id) {
        prjbasicinfoDetail({
            id: id
        }).then((res) => {
            if (res.data.code == 200) {
                this.addForm = res.data.data;
            }
        })
    },
    // 选择企业
    clickOpenCompanySelection(){
        if(!this.addForm.prjCode){
            this.$message.warning('请先选择项目');
            return
        }
        // this.$refs.blackCompanyDialog.personInit({prjId:this.addForm.id,userId:this.userId})
        this.$refs.blackCompanyDialog.personInit({prjId:this.addForm.id,phone:this.phone}) // 根据项目、人员电话去筛选第三方公司
    },
    // 选择企业弹窗返回的id
    changeCompany(row){
        this.addForm.companyName = row.companyName
        this.addForm.companyCode = row.companyCode
        this.addForm.regLocation = row.regLocation
    },
    // 详情处理
    // 新增时根据userId查看列表
    addCheckDetail(userId){
        prjblacklistuserlist({userId:userId}).then(res => {
            if(res.data.code == 200 && res.data.data && res.data.data.length != 0){
                this.$nextTick(()=>{
                    this.detailListId = res.data.data[0].blHeadId
                    this.detailList = res.data.data.reverse()
                })
            }else{
                this.detailList = []
            }
        })
      },
    // 查看或者编辑时候查看列表
    initDetail(id){
        this.detailListId = id
        prjblacklistuserlist({blHeadId:id}).then(res => {
            if(res.data.code == 200){
                this.$nextTick(()=>{
                    this.detailList =res.data.data.reverse()
                })
            }else{
                this.detailList = []
            }
        })
    },
    // 列表中修改说明 保存
    itemSaveBtn(refsName,item,index){
        let that = this
        this.$nextTick(()=>{
            console.log(item)
            that.$refs[refsName][0].validate((valid, done) => {
                if (valid) {
                    let queryData = {
                        id:item.id,
                        illustrate:item.illustrate,
                        files:item.files
                    }
                    prjblacklistuserlistSubmit(queryData).then(res => {
                        const {
                            msg,
                            data
                        } = res.data;
                        if (res.data.code == 200) {
                            that.initDetail(this.detailListId)
                            that.$message.success(that.$t('cip.cmn.msg.success.operateSuccess'))
                        } else {
                            that.$message.success(msg);
                        }
                        done()
                    }).catch(err => {
                        done()
                    }).finally(() => {
                    })
                }
            })
        })
    },
  }
};
</script>
<style lang="scss" scoped>
.blockBtnWrap{
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    height: 240px;
}
.imageBox{
    width:100px;
    height: 100px;
    border-radius: 20px;
    margin-bottom:10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
