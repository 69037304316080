<template>
    <div style="height: 100%; background-color: #ffffff">
      <head-layout
        :head-title="headTitle"
        :head-btn-options="headBtnOptions"
        @head-cancel="headCancel"
      ></head-layout>
      <div class="formContentBox">
          <div class="formMain">
              <el-form
                  ref="dataForm"
                  :model="dataForm"
                  label-width="150px"
                  :disabled="formType == 'view'"
              >
                      <div class="formTopic" style="margin-bottom: 12px"><span>个人信息</span></div>
                      <el-row>
                          <el-col :span="8">
                              <el-form-item label="姓名" prop="userName">
                                  <el-input v-model="dataForm.userName" disabled placeholder="请输入姓名"></el-input>
                              </el-form-item>
                          </el-col>
                          <el-col :span="8">
                              <el-form-item label="身份证号" prop="idCoding">
                                  <el-input v-model="dataForm.idCoding" disabled placeholder="请输入身份证号"></el-input>
                              </el-form-item>
                          </el-col>
                          <el-col :span="8">
                              <el-form-item label="性别" prop="sex">
                                    <el-select v-model="dataForm.sex" placeholder="请选择" disabled>
                                        <el-option
                                        v-for="item in sexOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                              </el-form-item>
                          </el-col>
                      </el-row>
                      <el-row>
                          <el-col :span="8">
                              <el-form-item label="年龄" prop="birthday">
                                  <el-input v-model="dataForm.birthday" disabled placeholder="请输入年龄"></el-input>
                              </el-form-item>
                          </el-col>
                          <el-col :span="8">
                              <el-form-item label="籍贯" prop="domicile">
                                  <el-input v-model="dataForm.domicile" disabled placeholder="请输入籍贯"></el-input>
                              </el-form-item>
                          </el-col>
                          <el-col :span="8">
                              <el-form-item label="工种" prop="post">
                                  <el-input v-model="dataForm.post" disabled placeholder="请输入工种"></el-input>
                              </el-form-item>
                          </el-col>
                      </el-row>
              </el-form>
              <div class="formTopic" style="margin-bottom: 12px">
                  <span>详情记录</span>
                  <el-button size="mini" @click="addBtn" v-if="formType != 'view'">新增</el-button>
              </div>
              <addForm ref="addForm" :formType="formType" :userId='dataForm.id' :phone="dataForm.phone"></addForm>
          </div>        
      </div>
    </div>
  </template>
  <script>
  import HeadLayout from "@/views/components/layout/head-layout";
  import addForm from "./components/addForm.vue"
  import { getAgeFromIdCard } from "@/util/util";
  export default {
    components: {
      HeadLayout,
      addForm
    },
    data() {
      return {
        headTitle: "",
        formType: "",
        dataForm:{},
        sexOptions:[
            {
                label: '男',
                value: 1
            },
            {
                label: '女',
                value: 2
            },
            {
                label: '未知',
                value: 3
            },
        ]
      };
    },
    computed: {
      headBtnOptions() {
        let result = [];
        result.push({
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        });
        return result;
      },
    },
    mounted() {
      this.init()
    },
    methods: {
      // 初始化新增/编辑/查看页面
      init(){
          let type = this.$route.query.type;
          this.formType = type;
          // row 新增传进来是弹窗中的row；而编辑和查看传进来的是黑名单组织列表中的row；故row在不同操作下点击进来的rowId不同，若有后续处理，需要注意。
          this.dataForm = this.$route.query.row ? JSON.parse(this.$route.query.row) : {}
          this.dataForm.birthday = this.dataForm.idCoding ? this.getAge(this.dataForm.idCoding) : ''
          if (type == "add") {
              this.headTitle = "新增黑名单人员";
            //   此时dataForm.id是新增弹窗的用户id
              this.$refs.addForm.addCheckDetail(this.dataForm.id)           
          } else if (type == "edit") {
              this.headTitle = "编辑黑名单人员";
              this.$refs.addForm.initDetail(this.dataForm.id)
          } else if (type == "view") {
              this.headTitle = "查看黑名单人员";
              this.$refs.addForm.initDetail(this.dataForm.id)
          }
      },
      getAge(data) {
        return getAgeFromIdCard(data)
      },
      // 点击新增按钮
      addBtn(){
          this.$refs.addForm.addModelShow = true
      },
      headCancel() {
          this.$router.$avueRouter.closeTag();
          this.$router.back();
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .formContentBox {
      padding: 12px;
      height: calc(100% - 76px);
  }
  .formMain{
      width: 100%;
      overflow: auto;
      box-sizing: border-box;
      padding: 0px 12px;
      height: 100%;
  }
  .formTopic {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgb(187, 187, 187);
    font-weight: 600 !important;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  </style>
  